import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { UpdateNotes } from '@/components/common/update-app-review/update-notes.interface';

export type Options = Omit<UseQueryOptions<unknown, DefaultError, UpdateNotes>, 'queryKey' | 'queryFn'>;

export const useSystemUpdatesQuery = (options?: Options) => {
  const _query = async (): Promise<UpdateNotes | ''> => {
    //  return {
    //    version: '1.9.0',
    //    id: '2435x6c7vb8n9m0',
    //    steps: [
    //      {
    //        title: 'Dashboard',
    //        description:
    //          'The Dashboard provides an overview of all your essential metrics and quick access to key areas of the application. Use this space to monitor user activity, view analytics, and manage notifications. This is the first place you land after logging in and the central hub for your daily operations.',
    //        imageUrl: 'https://dzifx11xkyskx.cloudfront.net/version-update/1.9.0/Screenshot+2025-03-28+at+11.53.23.png',
    //      },
    //      {
    //        title: 'Services',
    //        description:
    //          'The Services section lets you manage all the functionalities your application provides. You can add new services, edit existing ones, and configure integrations with third-party tools. It is designed to help you scale operations while maintaining flexibility and control across your app infrastructure.',
    //        videoUrl: 'https://dzifx11xkyskx.cloudfront.net/version-update/1.9.0/Screen%20Recording%202025-04-03%20at%2013.13.12.mov', // video
    //      },
    //      {
    //        title: 'Home',
    //        description:
    //          'The Home provides an overview of all your essential metrics and quick access to key areas of the application. Use this space to monitor user activity, view analytics, and manage notifications. This is the first place you land after logging in and the central hub for your daily operations.',
    //        imageUrl: 'https://dzifx11xkyskx.cloudfront.net/version-update/1.9.0/Screenshot+2025-03-28+at+11.53.23.png',
    //        videoUrl: 'https://maxtherapy-bucket-dev-public.s3.eu-central-1.amazonaws.com/version-update/1.9.0/Screen+Recording+2025-04-03+at+13.13.12.mov',
    //      },
    //    ],
    //  };
    const { data } = await axiosInterceptorInstance.get(`app-versions/info`);
    return data;
  };

  return useQuery({
    queryKey: ['app-versions/info'],
    queryFn: _query,
    refetchOnMount: false,
    ...options,
  });
};
