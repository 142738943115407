import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, UseMutationOptions, useMutation } from '@tanstack/react-query';

enum UserAnswer {
  YES = 'Yes',
  NO = 'No',
}

interface Request {
  therapistId: string;
  file: Blob;
  reportDate: string;
  comment: string;
  firstResponse: boolean;
  secondResponse: boolean;
  thirdResponse: boolean;
  isRestBreakRestricted: boolean;
  isLunchBreakRestricted: boolean;
}

export type Options = Omit<UseMutationOptions<string, DefaultError, Request>, 'mutationKey' | 'mutationFn'>;

export const useTherapistAttestationMutation = (options?: Options) => {
  const _mutation = async (value: Request): Promise<string> => {
    const { file, therapistId, firstResponse, secondResponse, thirdResponse, comment, reportDate } = value;
    let fd = new FormData();
    fd.append('file', file);
    fd.append('reportDate', reportDate as any);
    fd.append('firstResponse', firstResponse ? UserAnswer.YES : UserAnswer.NO);
    if (!value.isRestBreakRestricted) {
      fd.append('secondResponse', secondResponse ? UserAnswer.YES : UserAnswer.NO);
    }
    if (!value.isLunchBreakRestricted) {
      fd.append('thirdResponse', thirdResponse ? UserAnswer.YES : UserAnswer.NO);
    }
    fd.append('comment', comment);

    const { data } = await axiosInterceptorInstance.post(`therapists/${therapistId}/attestation-result`, fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: '*/*',
      },
    });
    return data;
  };

  return useMutation({
    mutationKey: [`therapists/attestation-result`],
    mutationFn: _mutation,
    ...options,
  });
};
