import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, UseMutationOptions, useMutation } from '@tanstack/react-query';

export type Options = Omit<UseMutationOptions<string, DefaultError, string>, 'mutationKey' | 'mutationFn'>;

export const useMarkSystemUpdateMutation = (options?: Options) => {
  const _mutation = async (id: string): Promise<string> => {
    const { data } = await axiosInterceptorInstance.post(`app-versions/${id}`);
    return data;
  };

  return useMutation({
    mutationKey: ['app-versions'],
    mutationFn: _mutation,
    ...options,
  });
};
