'use client';
import { Button, Dialog } from '@/components/ui';
import { DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useTranslations } from 'next-intl';
import { createRef, useEffect, useState } from 'react';
import {
  Checkbox,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Textarea,
} from '@/components/form';
import {
  TherapistAttestationValidationSchema,
  therapistAttestationValidationSchema,
} from './therapist-attestation-validation-schema';
import { defineStepper } from '@/components/ui/stepper';
import SignaturePad from 'react-signature-pad-wrapper';
import Image from 'next/image';

import * as Stepperize from '@stepperize/react';
import { LabelValueItem } from '../label-value-item';
import { cn } from '@/lib/utils';
import { useTherapistAttestationMutation } from '@/hooks/fetchers/mutations/therapists/useTherapistAttestationMutation';
import {
  TherapistAttestationStatus,
  useCheckTherapistAttestationStatusMutation,
} from '@/hooks/fetchers/mutations/therapists/useCheckTherapistAttestationStatusMutation';
import { useIsAllowed } from '@/lib/RBAC';
import { PERMISSIONS_TYPES } from '@/lib/RBAC/enums/permissions-types';
import { usePageVisibility } from '@/common/hooks';

const { StepperProvider, StepperNavigation, StepperStep, StepperTitle } = defineStepper(
  {
    id: 'step-1',
    title: 'Pages.TherapistAttestation.stepOne',
  },
  {
    id: 'step-2',
    title: 'Pages.TherapistAttestation.stepTwo',
  },
  {
    id: 'step-3',
    title: 'Pages.TherapistAttestation.stepThree',
  }
);
const TherapistAttestationDialog = () => {
  const t = useTranslations();
  const { checkPermissions, session } = useIsAllowed();
  const signaturePadRef = createRef<SignaturePad>();
  const [signatureData, setSignatureData] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [therapistAttestationStatus, setTherapistAttestationStatus] = useState<TherapistAttestationStatus>({
    showPopup: false,
    isRestBreakRestricted: false,
    isLunchBreakRestricted: false,
  });

  const { mutate: sendAttestationResult, isPending } = useTherapistAttestationMutation({
    onSuccess: () => {
      markAsDone(therapistAttestationStatus.reportDate as string);
    },
  });

  const { mutate: checkTherapistAttestationStatus } = useCheckTherapistAttestationStatusMutation({
    onSuccess: (value) => {
      setTherapistAttestationStatus(value);
      if (value.showPopup) {
        setIsOpen(true);
      }
    },
  });

  const checkTimeForShow = () => {
    const nowUTC = new Date();
    // Convert to PST (UTC -8)
    const nowPST = new Date(nowUTC.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }));
    const prevDayPST = new Date(nowUTC.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }));
    prevDayPST.setDate(nowPST.getDate() - 1);

    const hours = nowPST.getHours();
    const currentDate = nowPST.toISOString().split('T')[0]; // YYYY-DD-MM
    const previousDate = prevDayPST.toISOString().split('T')[0];

    const lastShownDate = localStorage.getItem(`THERAPIST_ATTESTATION_(${session?.user.userId})`);
    // const _hoursCompare = 20;
    const _hoursCompare = 6; // FOR TESTING

    if (
      ((hours < _hoursCompare && lastShownDate !== previousDate) ||
        (hours >= _hoursCompare && lastShownDate !== currentDate)) &&
      checkPermissions([PERMISSIONS_TYPES.SHOW_DAILY_THERAPIST_ATTESTATION])
    ) {
      checkTherapistAttestationStatus({
        therapistId: session?.user.userId as string,
      });
    }
  };

  usePageVisibility(() => {
    // 'User returned to the tab'
    if (!isOpen) {
      checkTimeForShow();
    }
  });

  useEffect(() => {
    checkTimeForShow();
  }, [JSON.stringify(session?.user.roles)]);

  useEffect(() => {
    if (therapistAttestationStatus.isRestBreakRestricted) {
      form.setValue('restBreakAcknowledgment', true);
    }
    if (therapistAttestationStatus.isLunchBreakRestricted) {
      form.setValue('mealBreakAcknowledgment', true);
    }
  }, [therapistAttestationStatus]);

  const markAsDone = (reportDate: string) => {
    localStorage.setItem(`THERAPIST_ATTESTATION_(${session?.user.userId})`, reportDate.split('T')[0]);
    setIsOpen(false);
  };

  const form = useForm<TherapistAttestationValidationSchema>({
    mode: 'onChange',
    resolver: zodResolver(therapistAttestationValidationSchema),
    defaultValues: {
      timeCertificationStatement: false,
      restBreakAcknowledgment: false,
      mealBreakAcknowledgment: false,
      comment: '',
    },
  });

  function onSubmitHandle(
    data: z.infer<typeof therapistAttestationValidationSchema>,
    methods: Stepperize.Stepper<any>
  ) {
    methods.next();
  }

  function saveSignature(methods: Stepperize.Stepper<any>) {
    if (signaturePadRef.current && !signaturePadRef.current.isEmpty()) {
      const data = signaturePadRef.current?.toDataURL();
      setSignatureData(data as string);
      methods.next();
    }
  }

  function onSubmit() {
    sendAttestationResult({
      therapistId: session?.user.userId as string,
      file: base64ToFile(signatureData as string),
      comment: form.getValues().comment,
      reportDate: therapistAttestationStatus.reportDate as string,
      firstResponse: form.getValues().timeCertificationStatement as boolean,
      secondResponse: form.getValues().restBreakAcknowledgment as boolean,
      thirdResponse: form.getValues().mealBreakAcknowledgment as boolean,
      isRestBreakRestricted: therapistAttestationStatus.isRestBreakRestricted,
      isLunchBreakRestricted: therapistAttestationStatus.isLunchBreakRestricted,
    });
  }

  function init() {
    if (signatureData) {
      signaturePadRef.current?.fromDataURL(signatureData);
    }
  }

  const base64ToFile = (base64String: string): Blob => {
    const base64Data = base64String.split(';base64,').pop();
    const byteCharacters = atob(base64Data as string);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/png' });
    return blob;
  };

  const handleClear = () => {
    signaturePadRef.current?.clear();
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent closeOnlyByAction className="w-full max-w-screen-xl">
        <DialogHeader>
          <DialogTitle>{t('Pages.TherapistAttestation.title')}</DialogTitle>
          <DialogDescription>{t('Pages.TherapistAttestation.description')}</DialogDescription>
        </DialogHeader>

        <StepperProvider className="space-y-4" variant="horizontal">
          {({ methods }) => (
            <>
              <StepperNavigation>
                {methods.all.map((step) => (
                  <StepperStep
                    key={step.id}
                    of={step.id}
                    onClick={() => {
                      switch (step.id) {
                        case 'step-2':
                          if (form.formState.isValid) {
                            methods.goTo(step.id);
                          }
                          break;
                        case 'step-3':
                          if (form.formState.isValid && signatureData) {
                            methods.goTo(step.id);
                          }
                          break;
                        default:
                          methods.goTo(step.id);
                          break;
                      }
                    }}
                  >
                    <StepperTitle>{t(step.title)}</StepperTitle>
                  </StepperStep>
                ))}
              </StepperNavigation>
              {methods.switch({
                'step-1': () => (
                  <Form {...form}>
                    <form onSubmit={form.handleSubmit((data) => onSubmitHandle(data, methods))} className="space-y-4">
                      <div className="h-[440px] space-y-4 rounded border bg-slate-50 p-4 pt-6">
                        <div className="relative">
                          <FormField
                            control={form.control}
                            name="timeCertificationStatement"
                            render={({ field }) => (
                              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                                <FormControl>
                                  <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                                </FormControl>
                                <div className="space-y-1 leading-none">
                                  <FormLabel className="cursor-pointer">
                                    {t('Pages.TherapistAttestation.preQuestion')}
                                  </FormLabel>
                                  <FormDescription>{t('Pages.TherapistAttestation.questionOne')}</FormDescription>
                                </div>
                              </FormItem>
                            )}
                          />
                        </div>

                        {!therapistAttestationStatus.isRestBreakRestricted && (
                          <div className="relative">
                            <FormField
                              control={form.control}
                              name="restBreakAcknowledgment"
                              render={({ field }) => (
                                <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                                  <FormControl>
                                    <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                                  </FormControl>
                                  <div className="space-y-1 leading-none">
                                    <FormLabel className="cursor-pointer">
                                      {t('Pages.TherapistAttestation.preQuestion')}
                                    </FormLabel>
                                    <FormDescription>{t('Pages.TherapistAttestation.questionTwo')}</FormDescription>
                                  </div>
                                </FormItem>
                              )}
                            />
                          </div>
                        )}

                        {!therapistAttestationStatus.isLunchBreakRestricted && (
                          <div className="relative">
                            <FormField
                              control={form.control}
                              name="mealBreakAcknowledgment"
                              render={({ field }) => (
                                <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                                  <FormControl>
                                    <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                                  </FormControl>
                                  <div className="space-y-1 leading-none">
                                    <FormLabel className="cursor-pointer">
                                      {t('Pages.TherapistAttestation.preQuestion')}
                                    </FormLabel>
                                    <FormDescription>{t('Pages.TherapistAttestation.questionThree')}</FormDescription>
                                  </div>
                                </FormItem>
                              )}
                            />
                          </div>
                        )}

                        <div className="relative py-4">
                          <FormField
                            control={form.control}
                            name="comment"
                            render={({ field }) => (
                              <FormItem className="w-full">
                                <FormLabel className="cursor-pointer">
                                  {t('Forms.therapistAttestationComment.label')}
                                </FormLabel>
                                <FormControl>
                                  <Textarea
                                    placeholder={t('Forms.therapistAttestationComment.placeholder')}
                                    className="resize-none bg-white"
                                    {...field}
                                  />
                                </FormControl>
                                <FormMessage className="absolute" />
                              </FormItem>
                            )}
                          />
                        </div>
                      </div>
                      <footer className="flex justify-end gap-4">
                        <Button type="submit">{t('Pages.TherapistAttestation.next')}</Button>
                      </footer>
                    </form>
                  </Form>
                ),
                'step-2': () => {
                  setTimeout(() => init(), 0);
                  return (
                    <>
                      <div className="relative h-[440px] content-center rounded border bg-slate-50">
                        <div className="flex h-full bg-white">
                          <SignaturePad
                            ref={signaturePadRef}
                            options={{ minWidth: 2, maxWidth: 6, penColor: 'rgb(66, 133, 244)' }}
                          />
                        </div>
                        <Button
                          className="absolute bottom-4 left-4"
                          variant="destructive"
                          size="sm"
                          onClick={handleClear}
                        >
                          {t('Buttons.clear')}
                        </Button>
                      </div>
                      <footer className="flex justify-end gap-4">
                        <Button variant="secondary" onClick={methods.prev} disabled={methods.isFirst}>
                          {t('Pages.TherapistAttestation.previous')}
                        </Button>
                        <Button onClick={() => saveSignature(methods)}>{t('Pages.TherapistAttestation.next')}</Button>
                      </footer>
                    </>
                  );
                },
                'step-3': () => {
                  const { timeCertificationStatement, restBreakAcknowledgment, mealBreakAcknowledgment, comment } =
                    form.getValues();

                  return (
                    <>
                      <div className="relative h-[440px] rounded border bg-slate-50 p-4 pt-6">
                        <div className="grid grid-cols-[50px_1fr] gap-2">
                          <p
                            className={cn(
                              'flex items-center text-sm font-semibold',
                              timeCertificationStatement ? 'text-green-600' : 'text-red-600'
                            )}
                          >
                            {timeCertificationStatement ? 'YES' : 'NO'}
                          </p>
                          <p className="text-sm font-medium text-gray-600">
                            {t('Pages.TherapistAttestation.answerOne')}
                          </p>

                          {!therapistAttestationStatus.isRestBreakRestricted && (
                            <>
                              <p
                                className={cn(
                                  'flex items-center text-sm font-semibold',
                                  restBreakAcknowledgment ? 'text-green-600' : 'text-red-600'
                                )}
                              >
                                {restBreakAcknowledgment ? 'YES' : 'NO'}
                              </p>
                              <p className="text-sm font-medium text-gray-600">
                                {t('Pages.TherapistAttestation.answerTwo')}
                              </p>
                            </>
                          )}

                          {!therapistAttestationStatus.isLunchBreakRestricted && (
                            <>
                              <p
                                className={cn(
                                  'flex items-center text-sm font-semibold',
                                  mealBreakAcknowledgment ? 'text-green-600' : 'text-red-600'
                                )}
                              >
                                {mealBreakAcknowledgment ? 'YES' : 'NO'}
                              </p>
                              <p className="text-sm font-medium text-gray-600">
                                {t('Pages.TherapistAttestation.answerThree')}
                              </p>
                            </>
                          )}
                        </div>
                        {comment && (
                          <LabelValueItem
                            className="pt-4"
                            label={t('Forms.therapistAttestationComment.label')}
                            value={comment}
                          />
                        )}
                        <div className="flex justify-end pt-4">
                          <Image width={300} height={150} alt="Signature" src={signatureData as string}></Image>
                        </div>
                      </div>
                      <footer className="flex justify-end gap-4">
                        <Button variant="secondary" onClick={methods.prev}>
                          {t('Pages.TherapistAttestation.previous')}
                        </Button>
                        <Button disabled={isPending} onClick={onSubmit}>
                          {t('Pages.TherapistAttestation.submit')}
                        </Button>
                      </footer>
                    </>
                  );
                },
              })}
            </>
          )}
        </StepperProvider>
      </DialogContent>
    </Dialog>
  );
};

TherapistAttestationDialog.displayName = 'TherapistAttestationDialog';

export { TherapistAttestationDialog };
