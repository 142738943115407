'use client';
import { Button, Dialog } from '@/components/ui';
import { DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import Image from 'next/image';
import { useSystemUpdatesQuery } from '@/hooks/fetchers/queries/useSystemUpdatesQuery';
import { useMarkSystemUpdateMutation } from '@/hooks/fetchers/mutations/useMarkSystemUpdateMutation';
import { ScrollArea } from '../scroll-area';

const UpdateAppReviewDialog = () => {
  const t = useTranslations();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState(0);

  const { data: updateNotes, refetch } = useSystemUpdatesQuery();

  const { mutate: markAsViewed } = useMarkSystemUpdateMutation({
    onSuccess: () => {
      setIsOpen(false);
    },
  });

  const handleNext = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const handlePrevious = () => {
    if (currentStep > 0) setCurrentStep((prev) => prev - 1);
  };

  const handleMarkAsViewed = () => {
    markAsViewed(updateNotes?.id as string);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      refetch(); // refetch every 15 min
    }, 15 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (updateNotes?.id) {
      setCurrentStep(0);
      setIsOpen(true);
    }
  }, [updateNotes])

  if (!updateNotes?.id) return;

  const step = updateNotes.steps[currentStep];
  const progressPercent =  (((currentStep + 1) / updateNotes.steps.length) * 100).toFixed(2);

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(v) => {
        setIsOpen(v);
        if (!v) {
          handleMarkAsViewed();
        }
      }}
    >
      <DialogContent closeByButtonOnly className="w-full max-w-screen-md">
        <DialogHeader>
          <DialogTitle>{t('Pages.UpdateAppDialog.title')}</DialogTitle>
        </DialogHeader>

        <div className="w-full py-2">
          <div className="mb-2 text-sm text-gray-400">
            {currentStep + 1}/{updateNotes?.steps.length}
            <span className="ml-2 font-bold text-gray-600">{step.title}</span>
          </div>

          <div className="h-2 w-full overflow-hidden rounded-full bg-gray-300">
            <div
              className="h-full rounded-full bg-blue-500 transition-all duration-500"
              style={{ width: `${progressPercent}%` }}
            />
          </div>
        </div>

        <ScrollArea className="h-[calc(100vh-456px)] overflow-auto">
          <span className="text-sm font-semibold">{step.description}</span>

          <div className="mt-8 flex w-full flex-col items-center space-y-8">
            {step.imageUrl && <Image width={640} height={0} src={step.imageUrl} alt={step.title} className="rounded" />}
            {step.videoUrl && (
              <video width="640" height="360" controls>
                <source src={step.videoUrl} type="video/mp4" />
                Browser not support
              </video>
            )}
          </div>
        </ScrollArea>

        <DialogFooter className="mt-10 flex h-20 flex-row items-center justify-between space-x-2 border-t border-t-gray-300 sm:justify-between">
          <Button variant="outline" type="button" size="lg" disabled={currentStep === 0} onClick={handlePrevious}>
            {t('Buttons.back')}
          </Button>

          <div className="space-x-4">
            <Button variant="outline" type="button" size="lg" onClick={handleMarkAsViewed}>
              {t('Buttons.skip')}
            </Button>
            <Button
              variant="secondary"
              type="button"
              size="lg"
              className="min-w-32"
              disabled={currentStep === updateNotes.steps.length}
              onClick={currentStep === updateNotes.steps.length - 1 ? handleMarkAsViewed : handleNext}
            >
              {currentStep === updateNotes.steps.length - 1 ? t('Buttons.finish') : t('Buttons.next')}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

UpdateAppReviewDialog.displayName = 'UpdateAppReviewDialog';

export { UpdateAppReviewDialog };
