import { z } from 'zod';

export const therapistAttestationValidationSchema = z
  .object({
    timeCertificationStatement: z.boolean().default(false).optional(),
    restBreakAcknowledgment: z.boolean().default(false).optional(),
    mealBreakAcknowledgment: z.boolean().default(false).optional(),
    comment: z
      .string({ message: 'Forms.therapistAttestationComment.required' })
      .max(250, { message: 'Forms.therapistAttestationComment.max' })
      .or(z.literal('')),
  })
  .superRefine((data, ctx) => {
    if (!data.timeCertificationStatement || !data.restBreakAcknowledgment || !data.mealBreakAcknowledgment) {
      if (data.comment.length < 100) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['comment'],
          message: 'Forms.therapistAttestationComment.min',
        });
      }
    }
  });

export type TherapistAttestationValidationSchema = z.infer<typeof therapistAttestationValidationSchema>;
