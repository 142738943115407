import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, UseMutationOptions, useMutation } from '@tanstack/react-query';

interface Request {
  therapistId: string;
}

export interface TherapistAttestationStatus {
  showPopup: boolean;
  reportDate?: string;
  isRestBreakRestricted: boolean;
  isLunchBreakRestricted: boolean;
}

export type Options = Omit<UseMutationOptions<TherapistAttestationStatus, DefaultError, Request>, 'mutationKey' | 'mutationFn'>;

export const useCheckTherapistAttestationStatusMutation = (options?: Options) => {
  const _mutation = async (value: Request): Promise<TherapistAttestationStatus> => {
    const { data } = await axiosInterceptorInstance.post(`therapists/${value.therapistId}/show-popup-attestation`);
    return data;
  };

  return useMutation({
    mutationKey: [`therapists/show-popup-attestation`],
    mutationFn: _mutation,
    ...options,
  });
};
